<div class="wheel-container">
    <div class="titled-canvas">
        <h1>{{title}}</h1>
        <canvas #wheelCanvas width="900" height="900"></canvas>
    </div>
    <mat-card class="controls">
        <mat-card-content>
            <app-slice-input
                    (slices)="setSlices($event, true)"
                    [slicesInput]="label"
                    (title)="setTitle($event)"
                    [titleInput]="title"></app-slice-input>
            <button mat-flat-button
                    color="primary"
                    class="stopButton"
                    (click)="toggleState()"
                    [disabled]="!buttonEnabled">{{buttonText}}</button>
            <button mat-flat-button
                    class="linkButton"
                    [cdkCopyToClipboard]="copyLink">Copy wheel url to clipboard</button>
        </mat-card-content>
    </mat-card>
</div>
