import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-slice-input',
    templateUrl: './slice-input.component.html',
    styleUrls: ['./slice-input.component.scss']
})
export class SliceInputComponent implements OnInit, OnDestroy {
    private sliceSubscription: Subscription;
    private titleSubscription: Subscription;
    sliceFormGroup: FormGroup;
    sliceControl: FormControl = new FormControl('', []);
    titleControl: FormControl = new FormControl('', []);

    @Output()
    slices = new EventEmitter<string[]>();
    @Input()
    slicesInput: string[];

    @Output()
    title = new EventEmitter<string>();
    @Input()
    titleInput: string;

    constructor(private readonly formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.sliceControl.setValue(this.slicesInput.join('\n'));
        this.titleControl.setValue(this.titleInput);
        this.sliceFormGroup = this.formBuilder.group(
            {
            sliceControl: this.sliceControl,
            titleControl: this.titleControl
            });
        this.sliceSubscription = this.sliceControl.valueChanges.subscribe(
            change =>
                this.slices.emit(
                    (change as string).split('\n')
                )
        );
        this.titleSubscription = this.titleControl.valueChanges.subscribe(
            change =>
                this.title.emit(
                    (change as string)
                )
        );
    }

    ngOnDestroy(): void {
        this.sliceSubscription.unsubscribe();
        this.titleSubscription.unsubscribe();
    }
}
