<form [formGroup]="sliceFormGroup">
    <mat-form-field class="text">
        <mat-label>Title</mat-label>
        <input matInput
               [formControl]="titleControl">
        <mat-hint>The title of your wheel of fortune.</mat-hint>
    </mat-form-field>
    <mat-form-field class="text">
        <mat-label>Slice labels</mat-label>
        <textarea matInput
                  [formControl]="sliceControl"
                  rows="10"></textarea>
        <mat-hint>Put in any number of labels for the slices.
            Each line will create a slice with the line as label.
            You have to enter at least 2 lines.</mat-hint>
    </mat-form-field>
</form>
