import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, UrlSerializer} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-wheel',
    templateUrl: './wheel.component.html',
    styleUrls: ['./wheel.component.scss']
})
export class WheelComponent implements OnInit, AfterViewInit {

    @ViewChild('wheelCanvas') wheelCanvas: ElementRef;
    private canvasContext: CanvasRenderingContext2D;
    private color = [
        '#9fb6ff',
        '#6c8fff',
        '#3968ff',
        '#1f54ff',
    ];
    label = [
        'No',
        'Yes',
        'Try again',
        'Definitely not',
        'Yeah!'
    ];
    private slices = this.label.length;
    private sliceDeg = 360 / this.slices;
    private deg: number;
    private speed: number;
    private slowDownRand = 0;
    private isStopped = false;
    private isStarted = false;
    private width: number;
    private center: number;
    private lock = false;
    buttonText = 'Turn';
    buttonEnabled = true;
    copyLink: string;
    title: string;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private serializer: UrlSerializer,
                private snackBar: MatSnackBar) {
        this.speed = 0;
        this.deg = this.randomNumberBetween(0, 360);
    }

    ngOnInit() {
        const hasSlices = this.route.snapshot.queryParamMap.has('nos');
        if (hasSlices) {
            let parsedSlices: string[] = [];
            const numberOfSlices = Number.parseInt(this.route.snapshot.queryParamMap.get('nos'), 10);
            for (let i = 0; i < numberOfSlices; i++) {
                const sliceParam = this.route.snapshot.queryParamMap.get('s'.concat(i.toString()));
                if (sliceParam) {
                    parsedSlices = parsedSlices.concat(decodeURI(sliceParam));
                }
            }
            this.setSlices(parsedSlices, false);
        }
        const hasTitle = this.route.snapshot.queryParamMap.has('title');
        if (hasTitle) {
            this.title = this.route.snapshot.queryParamMap.get('title');
        }
    }

    ngAfterViewInit(): void {
        this.canvasContext = (this.wheelCanvas.nativeElement as HTMLCanvasElement).getContext('2d');
        this.width = this.canvasContext.canvas.width;
        this.center = this.width / 2;
        this.drawImg();
    }

    randomNumberBetween(min, max) {
        return Math.random() * (max - min) + min;
    }

    deg2rad(deg) {
        return deg * Math.PI / 180;
    }

    drawSlice(deg, color) {
        this.canvasContext.beginPath();
        this.canvasContext.fillStyle = color;
        this.canvasContext.moveTo(this.center, this.center);
        this.canvasContext.arc(this.center, this.center, this.width / 2, this.deg2rad(deg), this.deg2rad(deg + this.sliceDeg));
        this.canvasContext.lineTo(this.center, this.center);
        this.canvasContext.fill();
    }

    drawText(deg, text: string) {
        this.canvasContext.save();
        this.canvasContext.translate(this.center, this.center);
        this.canvasContext.rotate(this.deg2rad(deg));
        this.canvasContext.textAlign = 'right';
        this.canvasContext.fillStyle = '#fff';
        this.canvasContext.font = 'bold 30px Roboto';
        this.canvasContext.fillText(
            text,
            this.width / 2 - 25,
            10,
            this.width / 2 - 75
        );
        this.canvasContext.restore();
    }

    drawPointer() {
        this.canvasContext.beginPath();
        this.canvasContext.fillStyle = '#fb560a';
        this.canvasContext.moveTo(this.center - 15, 0);
        this.canvasContext.lineTo(this.center + 15, 0);
        this.canvasContext.lineTo(this.center, 15);
        this.canvasContext.lineTo(this.center - 15, 0);
        this.canvasContext.fill();
    }

    drawImg() {
        this.canvasContext.clearRect(0, 0, this.width, this.width);
        for (let i = 0; i < this.slices; i++) {
            const colorIndex = i % (this.color.length - 1);
            this.drawSlice(this.deg, this.color[colorIndex]);
            this.drawText(this.deg + this.sliceDeg / 2, this.label[i]);
            this.deg += this.sliceDeg;
        }
        this.drawPointer();
    }

    animate() {
        if (this.isStarted) {
            this.deg = (this.speed + this.deg) % 360;

            if (!this.isStopped && this.speed < 10) {
                this.speed = this.speed + 0.5;
            }

            if (this.isStopped) {
                if (!this.lock) {
                    this.lock = true;
                    this.slowDownRand = this.randomNumberBetween(0.992, 0.998);
                }
                this.speed = this.speed > 0.2 ? this.speed *= this.slowDownRand : 0;
            }

            if (this.lock && !this.speed) {
                this.isStarted = false;
                this.isStopped = false;
                this.buttonEnabled = true;
                this.lock = false;
                let finalIndex = Math.floor(((360 - this.deg - 90) % 360) / this.sliceDeg);
                finalIndex = (this.slices + finalIndex) % this.slices;
                this.snackBar.open('You have got "' + this.label[finalIndex] + '"', 'OK');
            }

            this.drawImg();
            window.requestAnimationFrame(this.animate.bind(this));
        }
    }

    toggleState() {
        if (!(this.isStarted || this.isStopped)) {
            this.buttonText = 'Stop';
            this.isStarted = true;
            this.isStopped = false;
            this.animate();
        } else if (this.isStarted && !this.isStopped) {
            this.buttonText = 'Turn';
            this.buttonEnabled = false;
            this.isStopped = true;
        }
    }

    setSlices(newSlices: string[], draw: boolean) {
        while (newSlices.length < 2) {
            newSlices = newSlices.concat('');
        }
        this.label = newSlices;
        this.slices = Math.max(2, newSlices.length);
        this.sliceDeg = 360 / this.slices;
        if (draw) {
            this.drawImg();
        }
        this.copyLink = this.linkToCurrentWheel();
    }

    linkToCurrentWheel(): string {
        const queryParams = {nos: this.slices, title: this.title};
        for (let i = 0; i < this.slices; i++) {
            queryParams['s'.concat(i.toString())] = this.label[i];
        }
        const tree = this.router.createUrlTree([''], {queryParams});
        return window.location.href.replace(this.router.url, '').concat(this.serializer.serialize(tree));
    }

    setTitle(newTitle: string) {
        this.title = newTitle;
        this.copyLink = this.linkToCurrentWheel();
    }
}
